head {
  width: 150px;
}

.logo {
  width: 5%;
  float: left;
  margin: 0px 0px 0px 0px;
}

main {
  color: #555555;
  background-color: #fff;
  font-family: "Muli", Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  position: relative;
  margin: auto;
  min-height: 75vh; /* will cover the 100% of viewport */
  width: 100%;
}

button,
button.btn,
.btn {
  font-size: 1.2rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Amiko", sans-serif;
  color: #274C77;
  font-weight: bold;
}

a {
  font-family: "Amiko", sans-serif;
}

h1 {
  font-size: 5rem;
  text-align: center;
  color: white;
  margin: 0px;
  text-shadow: 0 3px 10px rgba(0, 0, 0, 0.7);
  line-height: 3;
}

h2 {
  font-size: 26px !important;
  text-align: center;
  margin-top: 20px;
}

h3 {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 550 !important;
}

h4 {
  text-align: left;
  margin-left: 12px;
  font-style: italic;
  font-size: 1.2rem !important;
}

h5 {
  font-size: 1.6rem;
  font-weight: bold;
}

p {
  text-align: center;
}

.separator {
  border-top-style: double;
  border-top-width: 5px;
  border-top-color: #6ec1e4;
}

.textLeft {
  text-align: left;
}

.loginText {
  text-align: center;
}

.topnav {
  display: flex;
  background-color: #E3A51A;
  border-radius: 0 0 10px 10px;
}

.topnav a {
  float: left;
  color:#274C77;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 20px;
}

.topnav a:hover {
  color: white;
}

.navbar-nav {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
  align-items: center;
}

.navbar-brand {
  display: flex !important;
  align-items: center;
}

.navbar-brand p{
    margin: 0 0 0 8px !important;
    color: #274C77;
    font-size: 18px;
    font-weight: 700;
}

.navbar-brand p:hover{
  color: white;
}

.navbar-nav li {
  padding-right: 15px;
}

.menu {
  padding: 0;
  margin: 0px;
  text-align: right;
}

.menu li {
  display: inline-block;
}

.menu a {
  display: inline-block;
  color: rgb(36, 27, 27);
  text-decoration: none;
  padding: 10px 30px;
}

.menu a:hover,
.menu a:focus .menu a:active {
  background-color: #bebec2;
}

.mainContainer {
  min-height: 100vh; /* will cover the 100% of viewport */
  display: flex;
  flex-direction: column;
  position: relative;
}

.footer {
  position: absolute;
  background-color: #000080;
  color: white;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 30px;
}

table {
  text-align: left;
  border-spacing: 0;
}

th,
td {
  padding: 10px 0px;
}

section {
  padding: 50px 15%;
  overflow: auto;
}

#left {
  float: left;
  width: 60%;
}
#right {
  float: left;
  width: 40%;
}

.nav-item > .active {
  color: rgb(12, 138, 221);
  font-weight: bold;
}

.container-progress-bar {
  align-self: center;
  padding-top: "500px";
  margin-top: 50px;
}

.login-form {
  max-width: 90%;
  margin-top: 30px;
}

.login-form .form-group {
  width: 100%;
}

.login-form .form-control {
  width: 300px;
  max-width: 100%;
}

.login-form-submit-btn {
  width: 60%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.share-button {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-section {
  position: relative;
}

.btn-toggle-password {
  position: absolute;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: 0;
  background-color: transparent;
  right: 20px;
  bottom: 4px;
  outline: none;
}

#btn-hide-password {
  display: none;
}

.logout {
  background-color: #E3A51A !important;
  border-color:  #E3A51A !important;
}
